import Home from "./pages/Home/home";

function App() {
  return (
    <div className="container">
      <Home></Home>
    </div>
  );
}

export default App;
