import './servicos.css'

function Servicos () {
    return (
        <section className='container-servicos' id='servicos'>
            <div className='container-left'>
                <h2 className='text-h2-servicos'>Serviços</h2>
                <p className='text-servicos'>
                    Na EMPR Serviços, proporcionamos soluções completas e especializadas em terceirização para atender às necessidades variadas de nossos clientes. Combinando experiência, excelência e um forte compromisso com a qualidade, oferecemos uma gama de serviços que garantem praticidade, eficiência e um atendimento excepcional.
                </p>
            </div>
            <div className='container-right'>
                <h2 className='text-h2-servicos'>Por que escolher a EMPR Serviços?</h2>
                <p className='text-servicos'>
                    Com mais de 10 anos de atuação no mercado, a EMPR Serviços é sinônimo de confiança e competência. Nossos valores se refletem em cada tarefa que realizamos, sempre buscando superar as expectativas e entregar um serviço de alta qualidade. Conheça de perto nossas soluções e permita-nos transformar o seu ambiente com a excelência e a dedicação que você merece.
                </p>
            </div>
        </section>
    )
}

export default Servicos;